import React from 'react';
import Layout from '../../../components/layout';
import Vacancy from '../../../components/shared-components/vacancy';

const DevOps = () => {
  return (
    <Layout>
      <Vacancy
        division="Development"
        vacancy="DevOps Engineer"
        workType="Full time"
        // location="Yerevan, Armenia"
        intro={
          <p>
            Our technical team is looking for a DevOps engineer who understands the Software Development Lifecycle and
            has the outright understanding of various automation tools for developing digital CI/CD pipelines.
          </p>
        }
        jobResponsibilities={
          <ul>
            <li className="secondary-text">
              Implement various development, testing, automation tools, and IT infrastructure
            </li>
            <li className="secondary-text">
              Plan the team structure, activities, and involvement in project management activities
            </li>
            <li className="secondary-text">Investigate and resolve technical issues</li>
            <li className="secondary-text">Set up new tools and required infrastructure</li>
            <li className="secondary-text">
              Define development, test, release, update, and support processes for DevOps operation
            </li>
            <li className="secondary-text">Encourage and build automated processes wherever possible</li>
            <li className="secondary-text">Manage certifications, keys and domain names for the environments</li>
            <li className="secondary-text">Select and deploy appropriate CI/CD tools.</li>
          </ul>
        }
        qualifications={
          <ul>
            <li className="secondary-text">2 + years of work experience as a DevOps Engineer</li>
            <li className="secondary-text">Experience with Google Cloud</li>
            <li className="secondary-text">Working knowledge of databases such as MongoDB, PostgreSQL</li>
            <li className="secondary-text">Experience with DevOps toolchains such as Docker</li>
            <li className="secondary-text">Experience managing production Kubernetes infrastructure</li>
            <li className="secondary-text">Awareness of critical concepts in DevOps and Scrum/Agile principles</li>
            <li className="secondary-text">Proficient in scripting, Git, and Git workflows</li>

            <li className="secondary-text">A good understanding of Python and NodeJs would be an asset</li>
            <li className="secondary-text">Experience with web servers (Apache, Nginx)</li>
            <li className="secondary-text">Excellent knowledge of English</li>
            <li className="secondary-text">Understanding customer requirements and project KPIs</li>

            <li className="secondary-text">Problem-solving attitude</li>
            <li className="secondary-text">Great communication, collaboration and representation skills.</li>
          </ul>
        }
      />
    </Layout>
  );
};

export default DevOps;
